<template>
    <div v-show="props.compType === 'kitsDayStudent'">
        <div class="doc">
            <img class="transfer-icon" :src="transferIcon" alt="走读申请图标" />
            <p class="title">走读申请套件</p>
        </div>
        <div class="shift-worker-audit-wrapper">
            <div class="shift-worker-audit">
                <el-switch v-model="props.reasonRequired" active-color="#3C7FFF" inactive-color="#CCCCCC"
                    :active-value="1" :inactive-value="0" @change="handlerReasonRequired"></el-switch>
                <span class="shift-worker-audit-label">申请原因必填</span>

            </div>
            <div class="shift-worker-audit">
                <el-switch v-model="props.attachmentRequired" active-color="#3C7FFF" inactive-color="#CCCCCC"
                    :active-value="1" :inactive-value="0" @change="handlerAttachmentRequired"></el-switch>
                <span class="shift-worker-audit-label">附件必上传</span>

            </div>
            <div class="shift-worker-audit">
                <el-switch v-model="props.isAllowMultipleApplicant" active-color="#3C7FFF" inactive-color="#CCCCCC"
                    :active-value="1" :inactive-value="0" @change="allowMultipleApplicant"></el-switch>
                <span class="shift-worker-audit-label">允许添加多个申请人</span>

            </div>
            <div>
                <div class="textarea-box">
                    <el-input class="input" v-model="props.dayStudentComment" type="textarea" placeholder="说明文字"
                    :rows="6" maxlength="300" show-word-limit ></el-input>
                </div>

                <el-form-item label="字体颜色">
                    <el-color-picker v-model="props.fontColor"></el-color-picker>
                </el-form-item>
                <el-form-item label="字体大小">
                    <el-input-number v-model="props.fontSize" :min="1" :max="30"></el-input-number>
                </el-form-item>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KitsShiftChangeSettings',
    components: {},
    props: ["props", "getFormId"],
    data() {
        return {

        }
    },
    filters: {},
    computed: {
        transferIcon() {
            return require("@/assets/images/transfer-icon.png");
        },
    },
    watch: {},
    created() {
        console.log('props',this.reasonRequired)
    },
    mounted() { },
    methods: {
        // 申请原因必填
        handlerReasonRequired() {
            console.log(this.props)
            this.$eventDispatch(
                "handlerReasonRequired",
                this.props.reasonLeaveRequired,
            );
        },
        // 附件必填
        handlerAttachmentRequired() {
            this.$eventDispatch(
                "handlerAttachmentRequired",
                this.props.reasonLeaveRequired,
            );
        },
        // 是否允许多个申请人
        allowMultipleApplicant() {
            this.$eventDispatch(
                "allowMultipleApplicant",
                this.props.reasonLeaveRequired,
            );
         },
    }
}
</script>

<style lang="scss" scoped>
.doc {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
    }

    .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.usage-scenarios {
    .usage-scenarios-label {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6D7073;
    }

    .usage-scenarios-radio-group {
        margin-top: 16px;
    }

    .usage-scenarios-des {
        box-sizing: border-box;
        margin-top: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: red;
        border-radius: 4px;

        p+p {
            margin-top: 3px;
        }
    }
}

.shift-worker-audit-wrapper {
    margin-top: 20px;

    .shift-worker-audit {
        display: flex;
        align-items: center;
        height: 40px;

        .shift-worker-audit-label {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 10px;
        }
    }

    .shift-worker-audit-des {
        background: #F7F7F7;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 13px 10px 15px 8px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-top: 14px;

        .shift-worker-audit-des-status {
            color: #333333;
        }

    }
}   .textarea-box{
            margin: 20px auto;
        }
</style>
